import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demo1 from '../assets/images/demo-screen-1.jpg';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead" id="page-top">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                EC Order
              </h1>
              <h3 className="mb-5">
                A self managed online ordering platform for restaurants and retail shops
              </h3>
              <Scroll type="id" element="contact">
                <a href="#contact" className="btn btn-outline btn-xl">
                  Learn more
                </a>
              </Scroll>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Why EC Order?</h2>
          <p className="text-muted">
            Check out what you can get from us!
          </p>
          <hr />
        </div>
        <div className="row">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Online Ordering</h3>
                    <p className="text-muted">
                      Save customers time. Save cashiers time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Menu Editing</h3>
                    <p className="text-muted">
                      Fully self-served. No assistance needed.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Pay-Per-Use</h3>
                    <p className="text-muted">
	              No order, no payment. 
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Analytics</h3>
                    <p className="text-muted">
                      Learn from your sales data.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          Interested? Contact us!
        </h2>
        <Social />
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
